// import OMath from '@/utils/math'
import dayjs from 'dayjs'
import {mapActions, mapState} from 'vuex'

export default {
  computed: {
    ...mapState('common', [
      'globalOptions','clientBlackData']),
    claimVipList(){
      return[
        {
          name: 'VIP',
          id: 1
        },
        {
          name: 'Claimed',
          id: 2
        },
      ]
    },
    refundOptions() {
      return [
        {
          id: 3,
          name: 'Cash'
        },
        {
          id: 2,
          name: 'Cheque'
        },

        {
          id: 4,
          name: 'ATM'
        },
      ]
    },
    makeStatusOptions() {
      return [
        {
          id: 1,
          name: 'Enable'
        },
        {
          id: 0,
          name: 'Disable'
        },

      ]
    },
    policyUploadTypeOptions() {
      return [
        {
          id: 1,
          name: 'Policy'
        },
        {
          id: 2,
          name: 'CI&Schedule'
        },

      ]
    },
    claimStatusOptions() {
      return [
        {
          name: 'Processing',
          id: 1
        },
        {
          name: 'Completed',
          id: 2
        },
        {
          name: 'Paid',
          id: 3
        }
      ]
    },
    titleOption(){
      return[
        {
          name:'Mr',
          id: 1
        },
        {
          name:'Miss',
          id: 2
        },
      ]
    },
    camcorderOption(){
      return[
        {
          name:'Not Installed',
          id: 2
        },
        {
          name:'Installed',
          id: 1
        },
      ]
    },
    modificationOption(){
      return[
        {
          name:'No Modification',
          id: 2
        },
        {
          name:'Modified',
          id: 1
        },

      ]
    },
    createdByOption(){
      return[
        {
          name:'All',
          id: ''
        },
        {
          name:'CS',
          id: 1
        },
        {
          name:'iPad',
          id: 2
        },
      ]
    },
    yearOption() {
      return (class_id) => {
        let {year = []} = this.globalOptions || {}
        let arr = year.filter(item => item.class_id == class_id) || []
        return arr ? arr[0].data : []
      }
    },
    accidentsOption(){
      return[
        {
          name:'No Accident',
          id: 1
        },
        {
          name:'Had Accident(s)',
          id: 2
        },
      ]
    },
    deductedOption(){
      return[
        {
          name:'No Point(s) Deducted',
          id: 1
        },
        {
          name:'Point(s) Deducted',
          id: 2
        },
      ]
    },
    confirmOption(){
      return[
        {
          name:'Confirm',
          id: 1
        },
        {
          name:'Not Confirm',
          id: 2
        },
      ]
    },
    insuredEmployeeTypeOption(){
      return[
        {
          name:'Indoor',
          id: 'Indoor'
        },
        {
          name:'Outdoor',
          id: 'Outdoor'
        },
      ]
    },
    clientList(){
      return[
        {
          name:'All',
          id: '0'
        },
        {
          name:'Blacklist',
          id: '1'
        }
      ]
    },
    allPolicyType(){
      return[
        {
          name:'All',
          id: '0'
        },
        // {
        //   name:'Incomplete',
        //   id: '7'
        // },
        {
          name:'Incomplete (Cover Note)',
          id: '9'
        },
        {
          name:'Incomplete (Policy)',
          id: '10'
        },
        {
          name:'Incomplete (Document)',
          id: '8'
        },
        // {
        //   name:'Pending Supporting Doc.',
        //   id: '1'
        // },
        // {
        //   name:'Pending Issue Policy',
        //   id: '2'
        // },
        // {
        //   name:'Pending Policy Doc.',
        //   id: '3'
        // },
        // {
        //   name:'Pending Post to Hire Purchase',
        //   id: '4'
        // },
        {
          name:'Paid On Account ',
          id: '5'
        },
        {
          name:'Client - Pending Refund',
          id: '6'
        },

      ]
    },
    pendingList(){
      return[
        {
          name:'Pending Renew Quote',
          id: 'pending_quota'
        },
        {
          name:'Paid & Issued',
          id: 'paid'
        },
        {
          name:'Expired',
          id: 'pending_expired'
        }
      ]
    },
    ticketList(){
      return[
        {
          name:'All',
          id: '0'
        },
        {
          name:'Pending',
          id: '2'
        },
        {
          name:'Paid',
          id: '3'
        },
        // {
        //   name:'Drafted',
        //   id: '1'
        // },
        {
          name:'No Show',
          id: '4'
        }
      ]
    },
    upComingList(){
      return[
        {
          name:'Upcoming Renew',
          id: 'upcoming_renew'
        },
        {
          name:'Expired',
          id: 'upcoming_expired'
        }
      ]
    },
    dispatchOption(){
      return[
        {
          name:'Collect',
          id: 1
        },
        {
          name:'Mail',
          id: 2
        },
        {
          name:' Keep in office',
          id: 3
        },
        {
          name:' Other',
          id: 4
        }
      ]
    },
    wayOptions(){
      return[
        // {
        //   name:'Pro-Rate',
        //   id: 1
        // },
        //
        // {
        //   name:'Short Period Table',
        //   id: 2
        // },
        {
          name:'Manual',
          id: 3
        },
      ]
    },
    wayExceeding(){
      return[
        {
          rate:'20% od annual rate',
          refund:'80% od annual rate',
          month: '1 month',
          id: 80
        },
        {
          rate:'30% od annual rate',
          refund:'70% od annual rate',
          month: '2 months',
          id: 70
        },
        {
          rate:'40% od annual rate',
          refund:'60% od annual rate',
          month: '3 months',
          id: 60
        },
        {
          rate:'50% od annual rate',
          refund:'50% od annual rate',
          month: '4 months',
          id: 50
        },
        {
          rate:'60% od annual rate',
          refund:'40% od annual rate',
          month: '5 months',
          id: 40
        },
        {
          rate:'70% od annual rate',
          refund:'30% od annual rate',
          month: '6 months',
          id: 30
        },
        {
          rate:'80% od annual rate',
          refund:'20% od annual rate',
          month: '7 months',
          id: 20
        },
        {
          rate:'Full annual rate',
          refund:'0% od annual rate',
          month: '8 months',
          id: 0
        },
      ]
    },
    selectDateOptions(){
      return[
        {
          name: 'Policy Effective Date',
          id: 1
        },
      ]
    },
    searchTypeOptions(){
      return[
        {
          name: 'Phone',
          id: 1
        },
        {
          name: 'Name',
          id: 2
        },
        {
          name: 'ID #',
          id: 6
        },

      ]
    },
    searchSecondTypeOptions(){
      return[
        {
          name: 'VRN #',
          id: 5
        },
        {
          name: 'Policy #',
          id: 3
        },
        {
          name: 'CN #',
          id: 7
        }
      ]
    },
    buttonClassOption(){
      return[
        {
          name: 'button-inforce',
          id: 200
        },
        {
          name: 'button-renew',
          id: 201
        },
        {
          name: 'button-endorsement',
          id: 203
        },{
          name: 'button-cancel',
          id: 204
        }
      ]
    },
    buttonColorClassOption(){
      return[
        {
          name: 'color-info',
          id: 30
        },
        {
          name: 'color-cancel',
          id: 40
        },
        {
          name: 'color-cancel',
          id: 50
        }
      ]
    },
    endorsementTypeOptions(){
      return[
        {
          name: 'Change Car',
          id: 1
        },
        {
          name: 'Change Driver',
          id: 2
        },
        {
          name: 'Change Plan Type',
          id: 3
        },
        {
          name: 'VRN',
          id: 4
        },
        {
          name: 'Change Contact Detail',
          id: 5
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    endorsementContractorTypeOptions(){
      return[
        {
          name: 'Change Contact Info',
          id: 1
        },
        {
          name: 'Change Coverage',
          id: 2
        },
        {
          name: 'Change Policy period',
          id: 3
        },
        {
          name: 'Change Work Site Location',
          id: 4
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    endorsementDomesticTypeOptions(){
      return[
        {
          name: 'Change Contact Info',
          id: 1
        },
        {
          name: 'Change Coverage',
          id: 2
        },
        {
          name: 'Change Domestic Helper Info',
          id: 3
        },
        {
          name: 'Change Insured Location',
          id: 4
        },
        {
          name: 'Change Policy period',
          id: 4
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    endorsementEmpolyeesTypeOptions(){
      return[
        {
          name: 'Change Contact Info',
          id: 1
        },
        {
          name: 'Change Coverage',
          id: 2
        },
        {
          name: 'Change Employees Info',
          id: 3
        },
        {
          name: 'Change Insured Location',
          id: 4
        },
        {
          name: 'Change Policy period',
          id: 4
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    endorsementBusinessTypeOptions(){
      return[
        {
          name: 'Change Contact Info',
          id: 1
        },
        {
          name: 'Change Coverage',
          id: 2
        },
        {
          name: 'Change Location of Risk',
          id: 3
        },
        {
          name: 'Change Policy period',
          id: 4
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    statusOptions(){
      return[
        {
          name: 'Pending Payment',
          id: 10
        },
        {
          name: 'Inforce',
          id: 20
        },
        {
          name: 'Inforce',
          id: 30
        },
        {
          name: 'Cancelled',
          id: 40
        },
        {
          name: 'Expired',
          id: 50
        },
      ]
    },
    policyTypeSelectOption(){
      return[
        {
          name: 'New Policy',
          id: 200
        },
        {
          name: 'Renew Policy',
          id: 201
        },
      ]
    },
    policyTypeOption(){
      return[
        {
          name: 'New Policy',
          id: 200
        },
        {
          name: 'Renew Policy',
          id: 201
        },
        {
          name: 'Endorsement',
          id: 203
        },{
          name: 'Cancel Policy',
          id: 204
        }
      ]
    },
    userTypeOptions() {
      return [
        {
          name: 'Personal',
          id: 1
        },
        {
          name: 'Company',
          id: 2
        }
      ]
    },
    planTypeOptions() {
      return [
        {
          name: 'Single',
          id: 5
        },
        {
          name: 'Annual',
          id: 6
        }
      ]
    },
    periodInsuranceOptions() {
      return [
        {
          name: 'One Year',
          id: 1
        },
        {
          name: 'Two Year',
          id: 2
        }
      ]
    },
    planOptions() {
      return [
        {
          name: 'T Plate',
          id: 3
        },
        {
          name: 'Named Driver',
          id: 4
        }
      ]
    },
    statusOption() {
      return[{
        name: 'Active',
        id: '1'
      },
      {
        name: 'Pending',
        id: '2'
      },
      {
        name: 'Banned',
        id: '0'
      }]
    },
    mathedOptions(){
      let {policy_dispatch_mathed=[]} = this.globalOptions
      return policy_dispatch_mathed||[]
      return [
        {
          name: 'Collect ',//发动机
          id: 1
        },
        {
          name: 'Mail ',//家务助理
          id: 2
        },
        {
          name: 'Keep in office',//打工度假
          id: 3
        },
        {
          name: 'Other',//旅行
          id: 4
        },
      ]
    },
    documentOptions(){
      return [
        {
          name: 'Driving Licence',//发动机
          id: 4
        },
        {
          name: 'HKID',//家务助理
          id: 2
        },
        {
          name: 'VRD',//打工度假
          id: 6
        },
        {
          name: 'NCD',//旅行
          id: 5
        },
        {
          name: 'Signed Proposal Form ',//室内装饰
          id: 7
        },
        {
          name: 'other',//留学生
          id: 20
        },
      ]
    },
    contractorDocumentOptions(){
      return [
        {
          name: 'BR / CI',
          id: 3
        },
        {
          name: 'Contract',
          id: 9
        },
        {
          name: 'Signed Proposal Form ',
          id: 7
        },
        {
          name: 'other',
          id: 20
        },
      ]
    },
    travelDocumentOptions(){
      return [
        {
          name: 'HKID',
          id: 2
        },
        {
          name: 'Singed Proposal Form',
          id: 7
        },
        {
          name: 'other',
          id: 20
        },
      ]
    },
    employeeDocumentOptions(){
      return [
        {
          name: 'BR / CI',
          id: 3
        },
        {
          name: 'MPF',
          id: 9
        },
        {
          name: 'Declaration Form',
          id: 11
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    businessDocumentOptions(){
      return [
        {
          name: 'BR / CI',
          id: 3
        },
        {
          name: 'MPF',
          id: 9
        },
        {
          name: 'Declaration Form',
          id: 11
        },
        {
          name: 'Singed Proposal Form',
          id: 7
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    conEmployeeDocumentOptions(){
      return [
        {
          name: 'BR / CI',
          id: 3
        },
        {
          name: 'Other',
          id: 20
        },
      ]
    },
    productNameOptions(){
      return [
        {
          name: 'Construction Insurance',//发动机
          id: 11
        },
        {
          name: 'Travel Insurance',//家务助理
          id: 12
        },
        {
          name: 'Home Insurance',//打工度假
          id: 13
        },
        {
          name: 'Employee Compensation',//旅行
          id: 14
        },
        {
          name: 'Business Package Insurance ',//室内装饰
          id: 15
        },
        {
          name: 'Domestic Helper Insurance',//留学生
          id: 16
        },
        {
          name: 'Construction Employee Compensation',//留学生
          id: 17
        },
      ]
    },
    // 產品類型
    productTypeOptions() {
      return [
        {
          name: 'Motor',//发动机
          id: 10
        },
        {
          name:'Contractors\' All Risks',
          id: 11
        },
        {
          name: 'Travel',
          id: 12
        },
        {
          name:'Home',
          id: 13
        },
        {
          name: 'Employees\' Compensation',
          id: 14
        },
        {
          name: 'Business Package',
          id: 15
        },
        {
          name: 'Domestic Helper',
          id: 16
        },
        {
          name: 'Construction Employee Compensation',
          id: 17
        },
        {
          name: 'Others',
          id: 18
        },
      ]
    },

    //验证身份 香港居民身份证/护照
    personalIdTypOptions() {
      return [
        {
          name: 'HKID',
          id: 1
        },
        {
          name: 'Passport',
          id: 2
        },
      ]
    },
    idTypeOptions() {
      return [
        {
          name: 'HKID',
          id: 1
        },
        {
          name: 'Passport',
          id: 2
        },
        {
          name: 'BR',
          id: 3
        }
      ]
    },
    carClassOptions(){
      return[
        {
          name: 'Private Car',
          id: 1001
        },
        {
          name: 'Commercial Vehicle',
          id: 1002
        },
        {
          name: 'Motorcycle',
          id: 1003
        },
        {
          name: 'Motor trade',
          id: 1004
        }
      ]
    },
    numberFourOptions() {
      return [
        {
          id: 1,
          name: 1
        },
        {
          id: 2,
          name: 2
        },
        {
          id: 3,
          name: 3
        },
        {
          id: 4,
          name: 4
        }
      ]
    },
    yesNoOptions() {
      return [
        {
          id: 1,
          name: 'Yes'
        },
        {
          id: 0,
          name: 'No'
        }
      ]
    },
    yes2Options() {
      return [
        {
          id: 2,
          name: 'No'
        },
        {
          id: 1,
          name: 'Yes'
        },

      ]
    },
    // 一样的，標記非必選吧
    yesNo2Options() {
      return [
        {
          id: 1,
          name: 'Yes'
        },
        {
          id: 2,
          name: 'No'
        }
      ]
    },
    yesNo3Options() {
      return [
        {
          id: 2,
          name: 'Yes'
        },
        {
          id: 1,
          name: 'No'
        }
      ]
    },
    allCoverTypeOptions() {
      return [
        {
          id: 1,
          name: 'Third Party'//第三方
        },
        {
          id: 2,
          name: 'Comprehensive'//全面
        },
        {
          id: 0,
          name: 'All'
        },
      ]
    },
    coverTypeOptions() {
      return [
        {
          id: 1,
          name: 'Third Party'
        },
        {
          id: 2,
          name: 'Comprehensive'
        },
      ]
    },
    classOptions() {
      return [
        {
          id: 11,
          name: 'Private Car'
        },
        {
          id: 12,
          name: 'Commercial Vehicle'
        },
        {
          id: 14,
          name: 'Motorcycle'
        },
        {
          id: 21,
          name: 'Domestic Helper'
        },
        {
          id: 22,
          name: 'Working Holiday'
        },
        {
          id: 23,
          name: 'Travel'
        },
        {
          id: 24,
          name: 'Interior Decoration'//室内装饰
        },
        {
          id: 25,
          name: 'Overseas Student'
        },
        {
          id: 26,
          name: 'Home & Fire'
        }
      ]
    },
    agentIconOptions(){
      return [
        {
          id: 9,
          name: 'AIG',
          logo: require('@/assets/images/agent/Liberty.png')
        },
        {
          id: 1,
          name: 'AIG',
          logo: require('@/assets/images/agent/Pacific.png')
        },
        {
          id: 13,
          name: 'Tokio Marine',
          logo: require('@/assets/images/agent/Tokio-Marine.png')
        },
        {
          id: 6,
          name: 'Zurich',
          logo: require('@/assets/images/agent/Zurich.png')
        },
      ]
    },
    consultantIconOptions(){
      return [
        {
          id: 1,
          name: 'AIG',
          logo: require('@/assets/images/consultant/AIG.png')
        },
        {
          id:  12,
          name: 'BANK OF CHINA(HONK KONG)',
          logo: require('@/assets/images/consultant/BOC.png')
        },
        {
          id: 4,
          name: 'China TaiPing',
          logo: require('@/assets/images/consultant/TaiPing.png')
        },
        {
          id: 11,
          name: 'QBE',
          logo: require('@/assets/images/consultant/QBE.png')
        },
      ]
    },
    ltdtIconOptions(){
      return [
        {
          id: 7,
          name: 'China Pacific',
          logo: require('@/assets/images/ltd/China-TaiBao.png')
        },
        {
          id: 8,
          name: 'Dah Sing',
          logo: require('@/assets/images/ltd/Dah-Sing.png')
        },
        {
          id: 12,
          name: 'BANK OF CHINA(HONK KONG)',
          logo: require('@/assets/images/consultant/BOC.png')
        },
      ]
    },
    insureOptions() {
      return [

        {
          id: 1,
          name: 'AIG',
          logo: require('@/assets/images/company/aig.png')
        },
        {
          id: 2,
          name: 'Tokio Marine',
          logo: require('@/assets/images/company/tokiomarine.png')
        },
        {
          id: 13,
          name: 'Tokio Marine',
          logo: require('@/assets/images/company/tokiomarine.png')
        },
        {
          id: 3,
          name: 'BANK OF CHINA(HONK KONG)',
          logo: require('@/assets/images/company/BOC.png')
        },
        {
          id: 4,
          name: 'China TaiPing',
          logo: require('@/assets/images/company/taiping.png')
        },
        {
          id: 6,
          name: 'Zurich',
          logo: require('@/assets/images/company/zurich.png')
        },
        {
          id: 7,
          name: 'China Pacific',
          logo: require('@/assets/images/company/cncpic.png')
        },
        {
          id: 8,
          name: 'Dah Sing',
          logo: require('@/assets/images/company/dahsing.png')
        },

        {
          id: 9,
          name: 'Liberty',
          logo: require('@/assets/images/company/liberty.png')
        },
        {
          id: 10,
          name: 'Pacific',
          logo: require('@/assets/images/company/cpic.png')
        },
        {
          id: 11,
          name: 'QBE',
          logo: require('@/assets/images/company/QBE.png')
        },
        {
          id: 12,
          name: 'BANK OF CHINA(HONK KONG)',
          logo: require('@/assets/images/company/BOC.png')
        },
        {
          id: 13,
          name: 'Tokio Marine',
          logo: require('@/assets/images/company/tokiomarine.png')
        },
      ]
    },
    insureSVGOptions() {
      return [
        {
          id:1,
          name: 'AIG',
          logo: require('@/assets/images/insurer/aig.svg')
        },
        {
          id: 2,
          name: 'Tokio Marine',
          logo: require('@/assets/images/insurer/tmf.png')
        },
        {
          id: 13,
          name: 'Tokio Marine',
          logo: require('@/assets/images/insurer/tmf.png')
        },
        {
          id: 8,
          name: 'Dah Sing',
          logo: require('@/assets/images/insurer/dahsing.png')
        },
        {
          id: 9,
          name: 'Liberty',
          logo: require('@/assets/images/insurer/liberty.png')
        },
        {
          id: 11,
          name: 'QBE',
          logo: require('@/assets/images/insurer/qbe.svg')
        },
        {
          id: 3,
          name: 'BOC',
          logo: require('@/assets/images/insurer/boc.svg')
        },
        {
          id: 12,
          name: 'BOC',
          logo: require('@/assets/images/insurer/boc.svg')
        },
        {
          id: 5,
          name: 'TMFK',
          logo: require('@/assets/images/insurer/tmf.png')
        },

        {
          id:6,
          name: 'Zurich',
          logo: require('@/assets/images/insurer/zurich.png')
        },
        {
          id: 4,
          name: 'TaiPing',
          logo: require('@/assets/images/insurer/taiping.svg')
        },

      ]
    },
    driverExperienceOptions() {
      return [
        {
          id: 1,
          name: 'less than 1 years',
        },
        {
          id: 2,
          name: '1-2  years',
        },
        {
          id: 3,
          name: '2-5 years',
        },
        {
          id: 4,
          name: 'more than 5 years',
        }
      ]
    },
    relationshipOptions() {
      return [
        {
          id: 1,
          name: 'Parent',
        },
        {
          id: 2,
          name: 'Child',
        },
        {
          id: 3,
          name: 'Spouse',
        },
        {
          id: 4,
          name: 'Relative',
        },
        {
          id: 5,
          name: 'Friend',
        },
        {
          id: 6,
          name: 'Employee',
        },
        {
          id: 7,
          name: 'Employer',
        },
        {
          id: 8,
          name: 'Self',
        },
      ]
    },

  },
  created() {
    // 檢查是否有遺漏的 options 數據未獲取成功，重新請求一次
    this.checkOptionsData()
  },
  methods: {
    ...mapActions('common', [
      'updateGlobalOptions',
      'checkOptionsData']),
    changeLineData(data){
      return data?data.replace(/\n/g, '<br/>'):'-'
    },
    getOptionName(target, id, originData) {
      try {
        let data
        if(originData) {
          data= originData
        }
        else {
          const targetArr = target.split('.')
          data = this[targetArr[0]]
          if(targetArr.length > 1) {
            data = this[targetArr[0]][targetArr[1]]
          }
        }

        let result = ''
        data.some(item => {
          if (item.id == id) {
            result = item.name
            return true
          }
          return false
        })
        return result
      } catch (e) {
      }
    },
    getArrOptionName(target, id, originData) {
      try {
        let data
        if(originData) {
          data= originData
        }
        else {
          const targetArr = target.split('.')
          data = this[targetArr[0]]
          if(targetArr.length > 1) {
            data = this[targetArr[0]][targetArr[1]]
          }
        }
        let result = ''
        id.some(item => {
          data.filter((arr_item)=>{
            if (arr_item.id == item) {
              if(arr_item.name)
                result = result?` ${result} , ${arr_item.name} `:result + ` ${arr_item.name} `
              // else result = result + `${arr_item.name} `
              return true
            }
          })
          return false
        })
        return result
      } catch (e) {
      }
    },
    getOptionData(target, id) {
      let result = {}
      this[target].some(item => {
        if (item.id == id) {
          result = item
          return true
        }
        return false
      })
      return result
    },
    getAreaName(target, id) {
      let result = ''
      this.globalOptions.area[target].some(item => {
        if (item.id == id) {
          result = item.name
          return true
        }
        return false
      })
      return result
    },
    getAreaData(target, id) {
      let result = {}
      this.globalOptions.area[target].some(item => {
        if (item.id == id) {
          result = item
          return true
        }
        return false
      })
      return result
    },
    changeTime(time){
      let arr =[]
      let result = ''
      if(time){
        arr = time.split('-')
        result = arr[2]+'-'+arr[1]+'-'+arr[0]
      }
      return result
    },
    showHkDate(time){
      if(time)
      {
        return  dayjs(time).format('DD-MM-YYYY')
      }else return  '-'
    },
    showHKTime(time){
      if(time)
      {
        return  dayjs(time).format('DD-MM-YYYY HH:mm:ss')
      }else return  '-'
    },
    showHKTimeSecond(time){
      if(time)
      {
        return  dayjs(time).format('DD/MM/YYYY HH:mm')
      }else return  '-'
    },
    showHKTimeArr(time){
      if(time)
      {
        return  dayjs(time).format('DD/MM/YYYY')
      }else return  '-'
    },
    showMonth(time){
      if(time)
      {
        return  dayjs(time).format('DD MMM YYYY')
      }else return  '-'
    },
    today(time){
      return  dayjs().format('YYYY-MM-DD')
    },
    showYearMonth(time){
      if(time)
      {
        return  dayjs(time).format('YYYY MMM')
      }else return  '-'
    },
    getNcdList(class_id){
      let list = []
      console.log('class_id--',class_id)
      this.globalOptions.ncd.some(item=>{
        if(item.class_id===class_id)
          list = item.item
      })
      return list
    },
    openFile(data) {
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    toFixed(data) {
      const arr = String(data).split('.')
      let num = arr[0] // 整数部分
      let resultArr = []
      while (num.length > 0) {
        resultArr.unshift(num.slice(-3))
        num = num.slice(0, -3)
      }
      let result = resultArr.join(',')
      return result
    },
    check18Years(begin, end) {
      if(!begin || !end) return true
      const years18 = dayjs(begin).add(18, 'years').format('YYYY-MM-DD')
      // 結束那一天也算，加一天來判斷
      const endDate = dayjs(end).add(1, 'day').format('YYYY-MM-DD')
      return dayjs(years18).isBefore(endDate)
    },
    effective_age(begin,end){
      if(!begin || !end) return true
      const birth = dayjs(end)
      const current = dayjs(begin)
      const age = current.diff(birth, 'year')
      return age
    },
    check70Years(date) {
      const birthDay = dayjs(date)
      const currentAge = dayjs().diff(birthDay, 'year')
      return currentAge >= 70
    },
  },

}
